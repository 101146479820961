import LoadingPage from '@/components/templates/LoadingPage';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { useAssessments } from '@/hooks/assessment/assessment';
import { TApiAssessment } from '@/types/Assessment.types';
import { useDisclosure } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import CreateAssessmentModal from '@/modules/assessment/CreateAssessmentModal';
import DeleteAssessmentModal from '@/modules/assessment/DeleteAssessmentModal';
import DeleteIcon from '@/components/atoms/DeleteIcon';
import CustomTable from '@/components/molecules/CustomTable';

const COLUMNS = ['Title', 'Type', 'Slug', 'Delete'];
type TSortingColumn = (typeof COLUMNS)[number];

const Assessments = () => {
  const [assessmentToDelete, setAssessmentToDelete] = useState<TApiAssessment | undefined>();
  const { isLoading, data: assessmentsData, pagination, isRefetching } = useAssessments();

  const { isOpen: isCreateModalOpen, onClose: onCreateModalClose, onOpen: onCreateModalOpen } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();

  const assessmentTableData = assessmentsData?.map((assessment) => ({
    ...assessment,
    id: assessment._id,
  }));

  const rawData = assessmentTableData ?? [];
  const data = { nodes: rawData };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item._id,
          cells: [
            item.title,
            item.type?.toLocaleLowerCase(),
            item.slug,
            <DeleteIcon
              onClick={(e: any) => {
                e.stopPropagation();
                onDeleteModalOpen();
                setAssessmentToDelete(item);
              }}
              centered={false}
            />,
          ],
        };
      }),
    [data.nodes],
  );

  if (isLoading) return <LoadingPage />;

  return (
    <SidebarItemViewTemplate
      title={'Assessments'}
      buttonLabel="Create New Assessment"
      onButtonClick={onCreateModalOpen}
    >
      <CustomTable<TApiAssessment[], TSortingColumn>
        columns={COLUMNS}
        dataWithId={data.nodes}
        isLoading={isLoading}
        isRefetching={isRefetching}
        pagination={pagination}
        tableListItems={tableListItems}
        noItemsMessage="No Assessments created yet"
        customColumnGridPercentages={[30, 20, 30, 20]}
        hasRowSelect
      />
      <CreateAssessmentModal isOpen={isCreateModalOpen} onClose={onCreateModalClose} />
      <DeleteAssessmentModal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} assessment={assessmentToDelete} />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/assessments/')({
  component: Assessments,
});
