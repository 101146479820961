import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import colors from '@/theme/colors';
import { Box, HStack, Text } from '@chakra-ui/react';
import type { CSSProperties } from 'react';

const ProfileBox = ({
  children,
  style,
  title,
  onClickEdit,
}: {
  children: React.ReactNode;
  style?: CSSProperties;
  title?: string;
  onClickEdit?: () => void;
}) => {
  return (
    <Box
      width="490px"
      padding={'40px'}
      boxShadow="0px 10px 80px 0px #00417926"
      background="white"
      borderRadius={'10px'}
      justifyContent={'center'}
      alignItems={'center'}
      style={style}
    >
      {title && onClickEdit && (
        <HStack justifyContent={'space-between'} width={'100%'}>
          <Text variant={'urbanistSemiBoldTitle'} marginBottom={'10px'}>
            {title}
          </Text>
          <CustomButton
            onClick={onClickEdit}
            backgroundColor="white"
            hoverBackgroundColor={'primary.100'}
            label="Edit"
            labelColor="text.darkBlue"
            style={{
              padding: 0,
              borderColor: colors.text.darkBlue,
              borderWidth: '2px',
            }}
            width={'110px'}
            height={'55px'}
            iconBeforeLabelSvgPath={svgIconPaths.pen}
            iconBeforeLabelSvgSize={16}
          />
        </HStack>
      )}
      {children}
    </Box>
  );
};

export default ProfileBox;
