import queryKeys from '@/constants/queryKeys';
import {
  getMemberCareOrganizationDashboard,
  getMemberCareOrganizationRoster,
} from '@/services/api/requests/member-care';
import { useQuery } from '@tanstack/react-query';
import { usePagination } from '../usePagination';
import { TMemberCareOrganizationRosterItem } from '@/types/MemberCare.types';

const useMemberCareOrganizationDashboard = (organizationId: string) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.memberCare.memberCareOrganizationDashboard, organizationId],
    staleTime: 0,
    queryFn: async () => {
      const response = await getMemberCareOrganizationDashboard(organizationId);

      return response;
    },
  });

  return { isLoading, data: data?.data, isError, error, isRefetching, refetch };
};

const useMemberCareOrganizationRoster = ({
  // sortingColumn,
  // sortingDirection,
  searchText,
  organizationId,
}: {
  searchText: string;
  organizationId: string;
}) => {
  // : { searchText: string; organizationId: string }
  return usePagination<TMemberCareOrganizationRosterItem[], { searchText: string; organizationId: string }>({
    query: getMemberCareOrganizationRoster,
    queryKey: queryKeys.memberCare.memberCareOrganizationRoster,
    // sortingColumn,
    // sortingDirection,
    queryParams: {
      searchText,
      organizationId,
    },
  });
};

export { useMemberCareOrganizationDashboard, useMemberCareOrganizationRoster };
