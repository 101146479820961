import { Box } from '@chakra-ui/react';
import React from 'react';
import ColorOverlay from '../ColorOverlay';
import iconsPng from '@/assets/img/png/icons';
import colors from '@/theme/colors';

const TopRightBackgroundCircle = () => {
  return (
    <Box position="absolute" width="250px" height="250px" right={0} top={0}>
      <Box
        width={'100%'}
        height={'100%'}
        position="absolute"
        backgroundImage={iconsPng.topRightBackground}
        backgroundSize="cover"
      ></Box>
      <ColorOverlay color1={colors.background.lightBlue} color1Alpha={0.9} />
    </Box>
  );
};

export default TopRightBackgroundCircle;
