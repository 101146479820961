import { TIMELINE_DATE_TEXT_DIMENSION, TIMELINE_ICON_DIMENSION, TIMELINE_ITEMS_GAP } from '@/constants/dimensions';
import colors from '@/theme/colors';
import { TTimeline } from '@/types/Timeline.types';
import getTimelineIconFromType from '@/utils/getTimelineIconFromType';
import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { formatDate } from 'date-fns';
import React, { useState } from 'react';

interface Props {
  firstName: string;
  memberTimelineItems: TTimeline[];
}

const MemberCareMemberWellnessTimeline = ({ firstName, memberTimelineItems }: Props) => {
  const [timelineCollapsed, setTimelineCollapsed] = useState(true);

  return (
    <VStack marginTop={'20px'} width={'100%'} position={'relative'} justifyContent={'center'}>
      <Text variant="loraSmallTitle" color="text.mediumGray" fontWeight={'500'} marginBottom={'20px'}>
        {firstName}'s Wellness Timeline
      </Text>
      <VStack
        justifyContent={'flex-start'}
        gap={'40px'}
        padding={'20px'}
        maxHeight={timelineCollapsed ? '380px' : ''}
        overflow={'hidden'}
      >
        {memberTimelineItems.map((timelineItem, i) => {
          const isScreenerOrAssessment = timelineItem.type === 'screener' || timelineItem.type === 'assessment';
          const totalScore = timelineItem.data.totalScore * 10;

          const contentDisplay = () => {
            if (isScreenerOrAssessment) {
              return (
                <Text variant={'urbanistSemiBold'} color="text.mediumBlue">
                  Took the screener
                </Text>
              );
            }

            if (
              timelineItem.type === 'memberCareNote' ||
              timelineItem.type === 'selfNote' ||
              timelineItem.type === 'observation'
            ) {
              return (
                <VStack gap={0}>
                  <HStack gap={'4px'} width={'100%'} justifyContent={'flex-start'}>
                    {timelineItem.type === 'memberCareNote' || timelineItem.type === 'observation' ? (
                      <>
                        <Text variant={'urbanistSemiBold'} color="text.mediumBlue">
                          {timelineItem.type === 'memberCareNote' ? 'Note' : 'Observation'} from
                        </Text>
                        <Text variant={'urbanistSemiBold'} color="primary.500">
                          {timelineItem.data.memberCare?.firstName ?? 'Member Specialist'}
                          {timelineItem.data.memberCare?.firstName ? ' ' : ''}
                          {timelineItem.data.memberCare?.lastName}:
                        </Text>
                      </>
                    ) : (
                      <Text variant={'urbanistSemiBold'} color="text.mediumBlue">
                        Note to yourself:
                      </Text>
                    )}
                  </HStack>
                  <Text
                    variant={'loraItalic'}
                    color={'text.mediumBlue'}
                    maxWidth={'650px'}
                    textAlign={'start'}
                    width={'100%'}
                  >
                    {timelineItem.data.content}
                  </Text>
                </VStack>
              );
            }

            return timelineItem.data.content;
          };

          return (
            <HStack position={'relative'} width={'100%'} gap={`${TIMELINE_ITEMS_GAP}px`} key={timelineItem.id}>
              <Text variant={'urbanistSemiBold'} color={'text.mediumBlue'} width={`${TIMELINE_DATE_TEXT_DIMENSION}px`}>
                {formatDate(new Date(timelineItem.createdAt), 'dd. MMM')}
              </Text>
              <Center
                width={TIMELINE_ICON_DIMENSION}
                height={TIMELINE_ICON_DIMENSION}
                backgroundColor={'extra.white'}
                borderRadius={'full'}
                boxShadow={'0px 3.18px 15.88px 0px #00417933'}
                zIndex={2}
              >
                {getTimelineIconFromType(timelineItem.type)}
              </Center>
              <HStack gap={'4px'}>
                {contentDisplay()}
                {isScreenerOrAssessment && (
                  <Text variant={'urbanistSemiBold'} color={'primary.500'}>
                    Wellness Score: {totalScore.toFixed()}.
                  </Text>
                )}
              </HStack>
              {i < memberTimelineItems.length - 1 && (
                <Box
                  position={'absolute'}
                  top={TIMELINE_ICON_DIMENSION}
                  left={`${TIMELINE_DATE_TEXT_DIMENSION + TIMELINE_ITEMS_GAP + TIMELINE_ICON_DIMENSION / 2 - 1}px`}
                  height={'80px'}
                  width={'2px'}
                  backgroundColor={'#CBDEED'}
                  zIndex={1}
                ></Box>
              )}
            </HStack>
          );
        })}
      </VStack>

      {memberTimelineItems.length > 4 && (
        <Center
          id="the-radial-color-overlay"
          position="absolute"
          transition={'height 0.1s'}
          bgGradient={
            timelineCollapsed
              ? `radial-gradient(${colors.background.lightBlue}, ${colors.background.lightBlue}ee, ${colors.background.lightBlue}cc, ${colors.background.lightBlue}aa, ${colors.background.lightBlue}30, ${colors.background.lightBlue}10)`
              : `radial-gradient(${colors.background.lightBlue}, ${colors.background.lightBlue}10)`
          }
          width={'130%'}
          height={timelineCollapsed ? '150px' : '100px'}
          bottom={timelineCollapsed ? '-30px' : '-85px'}
          left={'-180px'}
          zIndex={10}
          paddingLeft={'100px'}
        >
          <Text
            variant={'urbanistSemiBoldSmallTitle'}
            zIndex={11}
            cursor={'pointer'}
            _hover={{ fontWeight: 700 }}
            color={'primary.500'}
            fontSize="18px"
            onClick={() => setTimelineCollapsed((prev) => !prev)}
          >
            Show {timelineCollapsed ? 'more' : 'less'}
          </Text>
        </Center>
      )}
    </VStack>
  );
};

export default MemberCareMemberWellnessTimeline;
