import authRoutes from '@/constants/routes/auth';
import { useRouter, Link } from '@tanstack/react-router';
import React from 'react';
import { HStack, Link as ChakraLink } from '@chakra-ui/react';
import sharedRoutes from '@/constants/routes/shared';
import authMessages from '@/messages/auth';

const AuthHeader = () => {
  const {
    state: {
      location: { pathname },
    },
  } = useRouter();
  const isSignIn = pathname === authRoutes.signIn;
  const isContactSupport = pathname === sharedRoutes.contactSupport;

  return (
    <HStack spacing={'14px'}>
      {!isSignIn && (
        <ChakraLink
          as={Link}
          to={authRoutes.signIn}
          minWidth={'100px'}
          fontSize={'14px'}
          color={isContactSupport ? 'text.mediumGray' : 'white'}
          fontWeight={'700'}
          lineHeight={'18px'}
        >
          {authMessages.signIn}
        </ChakraLink>
      )}
      {/* {!isContactSupport && <Button as={Link} variant={'headerLinkButton'} to={sharedRoutes.contactSupport}
        leftIcon={<HStack width={'20px'} alignItems={'center'}>
          <SvgIcon iconPath={svgIcons.email} size={19} color={text.mediumGray} />
        </HStack>}
      >
        {authMessages.contactSupport}
      </Button>} */}
    </HStack>
  );
};

export default AuthHeader;
