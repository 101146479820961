import { TMemberCareMemberNote, TMemberCareMemberNoteType } from '@/types/MemberCare.types';
import { api, TCustomAxiosResponse } from '..';

const rootPath = '/v1/notes';

const endpoints = {
  notes: `${rootPath}`,
  notesForMember: `${rootPath}/user`,
};

export const getNotesForMemberCareMember = async (
  memberId: string,
): Promise<TCustomAxiosResponse<TMemberCareMemberNote[]>> => {
  const { data: response } = await api.get<TCustomAxiosResponse<TMemberCareMemberNote[]>>(
    `${endpoints.notesForMember}/${memberId}`,
  );
  return response;
};

export const createNoteForMemberCareMember = async (
  content: string,
  createdFor: string,
  type: TMemberCareMemberNoteType,
): Promise<string> => {
  const { data } = await api.post(endpoints.notes, { content, createdFor, type });

  const result = data.data;

  return result;
};

export const editNote = async (noteId: string, newContent: string): Promise<string> => {
  const { data } = await api.put(`${endpoints.notes}/${noteId}`, { content: newContent });

  const result = data.data;

  return result;
};

export const deleteNote = async (noteId: string): Promise<string> => {
  const { data } = await api.delete(`${endpoints.notes}/${noteId}`);

  const result = data.data;

  return result;
};
