import { svgIconPaths } from '@/assets/svg/icons';
import { TTimelineType } from '@/types/Timeline.types';
import SvgIcon from '@/components/atoms/SvgIcon';
import colors from '@/theme/colors';

const getTimelineIconFromType = (timelineType: TTimelineType) => {
  switch (timelineType) {
    case 'assessment':
    case 'screener':
      return (
        <SvgIcon
          iconPath={svgIconPaths.assessment}
          color={colors.text.mediumBlue}
          size={25}
          boxWidth={18}
          boxHeight={17}
        />
      );

    case 'memberCareNote':
    case 'selfNote':
    default:
      return (
        <SvgIcon iconPath={svgIconPaths.note} color={colors.text.mediumBlue} size={25} boxWidth={14} boxHeight={18} />
      );
  }

  // return <SvgIcon iconPath={svgIcons.phone} color={colors.text.mediumBlue} size={25} boxWidth={18} boxHeight={17} />;
  // return <Image alt="date-input-calendar" src={svgIcons.calendar} height={'16px'} width={'16px'} />;
};

export default getTimelineIconFromType;
