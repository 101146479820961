import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import colors from '@/theme/colors';
import { HStack, Text } from '@chakra-ui/react';
import React from 'react';

const getCircleArrowTextDisplayFromTrend = (trend: string) => {
  const isNegative = trend[0] === '-';
  let trimmedTrend = trend;

  if (isNegative) {
    trimmedTrend = trend.substring(1);
  }

  return (
    <HStack gap={'2px'}>
      <Text variant={'urbanistSmallBold'} color={'text.mediumGray'}>
        {parseFloat(trimmedTrend).toFixed()}%
      </Text>
      {isNegative ? (
        <SvgIcon iconPath={svgIconPaths.arrowDown} color={colors.extra.red} size={10} />
      ) : (
        <SvgIcon iconPath={svgIconPaths.arrowUp} color={colors.extra.teal} size={10} />
      )}
    </HStack>
  );
};

export default getCircleArrowTextDisplayFromTrend;
