import { THistoricalMonthly } from '@/types/Organization.types';
import { Chart } from 'react-charts';

import { HStack, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import CheckBoxTile from '@/components/atoms/CheckBoxTile';

type Props = {
  data?: THistoricalMonthly[];
};

const HistoricalDataChart = ({ data }: Props) => {
  const [visibleSeries, setVisibleSeries] = useState([
    'Score Progress',
    'Users Submitted Screener',
    'Submitted Screeners',
  ]);

  interface ToggleSeries {
    (label: string): void;
  }

  const toggleSeries: ToggleSeries = (label) => {
    setVisibleSeries((prev) => {
      if (prev.includes(label)) {
        // Prevent the last series from being removed
        if (prev.length === 1) {
          alert('At least one series must be selected.');
          return prev;
        }
        return prev.filter((item) => item !== label);
      } else {
        return [...prev, label];
      }
    });
  };

  const allSeries = [
    {
      label: 'Score Progress',
      color: '#00D2AD', //
      data:
        data?.map((entry) => ({
          x: `${entry.year}-${String(entry.month).padStart(2, '0')}`,
          y: entry.progress.totalScore * 10,
        })) ?? [],
    },
    {
      label: 'Submitted Screeners',
      color: '#5F4DCE',
      data:
        data?.map((entry) => ({
          x: `${entry.year}-${String(entry.month).padStart(2, '0')}`,
          y: entry.progress.totalSubmittedScreeners,
        })) ?? [],
    },
    {
      label: 'Users Submitted Screener',
      color: '#FF8811',
      data:
        data?.map((entry) => ({
          x: `${entry.year}-${String(entry.month).padStart(2, '0')}`,
          y: entry.progress.usersSubmitted,
        })) ?? [],
    },
    // {
    //   label: 'Users Invited',
    //   color: '#fd7e14',
    //   data:
    //     data?.map((entry) => ({
    //       x: `${entry.year}-${String(entry.month).padStart(2, '0')}`,
    //       y: entry.users.invited,
    //     })) ?? [],
    // },
    // {
    //   label: 'Users Signed Up',
    //   color: '#20c997',
    //   data:
    //     data?.map((entry) => ({
    //       x: `${entry.year}-${String(entry.month).padStart(2, '0')}`,
    //       y: entry.users.signedUp,
    //     })) ?? [],
    // },
  ];

  const filteredSeries = allSeries.filter((series) => visibleSeries.includes(series.label));

  const primaryAxis = {
    getValue: (datum: { x: string; y: number }) => datum.x,
    label: 'Month',
  };

  const secondaryAxes = [
    {
      getValue: (datum: { x: string; y: number }) => datum.y,
      label: 'Values',
      elementType: 'line' as const,
    },
  ];

  return (
    <HStack width={'100%'} height={'400px'} gap={'20px'}>
      <VStack flex={1} height={'400px'}>
        <Chart
          options={{
            data: filteredSeries,
            primaryAxis,
            secondaryAxes,
            defaultColors: filteredSeries.map((series) => series.color),
          }}
        />
      </VStack>
      <VStack alignItems={'flex-start'} gap={'20px'}>
        {allSeries.map((series) => (
          <CheckBoxTile
            key={series.label}
            isChecked={visibleSeries.includes(series.label)}
            checkboxOption={{ label: series.label }}
            onClick={() => toggleSeries(series.label)}
            buttonStyle={{ backgroundColor: series.color }}
            borderColor={series.color}
          >
            <VStack width={'16px'} height={'16px'} borderRadius={'full'} bg={series.color} />
          </CheckBoxTile>
        ))}
      </VStack>
    </HStack>
  );
};

export default HistoricalDataChart;
