import { TMemberCareMemberObservation } from '@/types/MemberCare.types';
import { api, TCustomAxiosResponse } from '..';

const rootPath = '/v1/observations';

const endpoints = {
  observations: `${rootPath}`,
  observationForMember: `${rootPath}/user`,
};

export const getObservationsForMemberCareMember = async (
  memberId: string,
): Promise<TCustomAxiosResponse<TMemberCareMemberObservation[]>> => {
  const { data: response } = await api.get<TCustomAxiosResponse<TMemberCareMemberObservation[]>>(
    `${endpoints.observationForMember}/${memberId}`,
  );
  return response;
};

export const createObservationForMemberCareMember = async (content: string, memberId: string): Promise<string> => {
  const { data } = await api.post(endpoints.observations, { content, userId: memberId });

  const result = data.data;

  return result;
};

export const editObservation = async (observationId: string, newContent: string): Promise<string> => {
  const { data } = await api.put(`${endpoints.observations}/${observationId}`, { content: newContent });

  const result = data.data;

  return result;
};

export const deleteObservation = async (observationId: string): Promise<string> => {
  const { data } = await api.delete(`${endpoints.observations}/${observationId}`);

  const result = data.data;

  return result;
};
