import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './services/queryClient';
import App from './App';
import { AuthProvider } from './context/auth';

import './global.css';

import * as Sentry from '@sentry/react';
import isDebug from './utils/isDebug';
import DocumentProvider from './providers/DocumentProvider/DocumentProvider';
import components from './providers/DocumentProvider/components';

Sentry.init({
  enabled: !isDebug,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.browserProfilingIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'], // import.meta.env.VITE_API_BASE_URL
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: `${import.meta.env.PACKAGE_VERSION}-${import.meta.env.VITE_GIT_COMMIT}`,
  environment: `${import.meta.env.VITE_SENTRY_ENV}`,
});

const rootElement = document.getElementById('root')!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <DocumentProvider components={components}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </DocumentProvider>
      </QueryClientProvider>
    </StrictMode>,
  );
}
