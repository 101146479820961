import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import TabButtons from '@/components/atoms/TabButtons';
import MemberCareOrganizationsTableDisplay from '@/modules/memberCare/MemberCareOrganizationsTableDisplay';
import MemberCareMembersTableDisplay from '@/modules/memberCare/MemberCareMembersTableDisplay';
import { Flex, VStack } from '@chakra-ui/react';

const MemberCareMembers = () => {
  const [memberCareSection, setMemberCareSection] = useState<'members' | 'organizations'>('members');

  return (
    <AppPageTemplate>
      <VStack width={MAX_CONTAINER_WIDTH}>
        <Flex justify={'start'} width={'100%'} marginBottom={'20px'}>
          <TabButtons
            options={[
              {
                onClick: () => setMemberCareSection('members'),
                labelValue: 'members',
                labelDisplay: 'Members',
              },
              {
                onClick: () => setMemberCareSection('organizations'),
                labelValue: 'organizations',
                labelDisplay: 'Organizations',
              },
            ]}
            selectedOptionValue={memberCareSection}
          />
        </Flex>

        <VStack display={memberCareSection === 'members' ? 'flex' : 'none'} width={'100%'}>
          <MemberCareMembersTableDisplay />
        </VStack>

        <VStack display={memberCareSection === 'organizations' ? 'flex' : 'none'} width={'100%'}>
          <MemberCareOrganizationsTableDisplay />
        </VStack>
      </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/members/')({
  component: MemberCareMembers,
});
