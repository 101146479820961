'use client';

import React, { useState } from 'react';
import { Slider as ChakraSlider, Image, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';
import { colors, colorsInverted } from './colors';

type Props = {
  setValue: (_: number) => void;
  value?: number;
  defaultValue?: number;
  sliderValues?: Array<number>;
  step?: number;
  min?: number;
  max?: number;
  inverted?: boolean;
};

const Slider = ({
  setValue,
  value = 50,
  step = 25,
  min = 0,
  max = 100,
  // sliderValues = [0, 25, 50, 75, 100],
  inverted = false,
}: Props) => {
  // used for more often updates, to display color change
  const [localValue, setLocalValue] = useState(value);
  const [localIsAnswered, setLocalIsAnswered] = useState(false);

  return (
    <ChakraSlider
      step={step}
      min={min}
      max={max}
      defaultValue={localValue}
      focusThumbOnChange={false}
      onChange={(val) => {
        setLocalValue(val);
        setLocalIsAnswered(true);
      }}
      onChangeEnd={setValue}
      // onDrag={(e) => console.log('e.target.value', e.currentTarget.nodeValue)}
      // onDragEnd={(e) => console.log('e.target.value', e.currentTarget.nodeValue)}
      // onVolumeChange={(e) => console.log('e.', e.currentTarget.nodeValue)}
      height={'20px'} // to make it easier to click-choose a value
      width={'97%'} // squish the Slider a bit on the sides, to help center the dot at the values "Less" and "More"
      marginTop={'10px'}
    >
      <SliderTrack
        height={'3px'}
        bgGradient={inverted ? 'linear(90deg, #00B547,#A2E2C4,#EC4D84)' : 'linear(90deg, #EC4D84,#A2E2C4,#00B547)'}
      >
        <SliderFilledTrack backgroundColor={'transparent'} />
      </SliderTrack>

      {localIsAnswered ? (
        <SliderThumb
          backgroundColor={inverted ? colorsInverted[localValue] : colors[localValue]}
          borderColor={'white'}
          boxSize="30px"
          borderWidth="4px"
        />
      ) : (
        <SliderThumb boxSize={'30px'} borderRadius={'full'} borderWidth={3}>
          <Image width={'24px'} height={'24px'} alt="as" src="/img/png/icons/slider-circle-circled.png" />
        </SliderThumb>
      )}
    </ChakraSlider>
  );
};

export default Slider;
