import { memberCareRoutes } from '@/constants/routes/app';
import menuMessages from '@/messages/menu';

export type TMenuOption = {
  label: string;
  navigateTo: string;
};

export const memberCareRoleMenuOptions: TMenuOption[] = [
  {
    label: menuMessages.myMembers,
    navigateTo: memberCareRoutes.memberCareMembers,
  },
  {
    label: menuMessages.myProfile,
    navigateTo: memberCareRoutes.memberCareProfile,
  },
];
