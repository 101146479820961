import React from 'react';

import { Button, Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import MenuPlaceholder from './components/MenuPlaceholder';
import ProfileMenu from './components/ProfileMenu';

interface Props {
  isHeaderTransparent: boolean;
}

const ProfileDropdownMenu = ({ isHeaderTransparent }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={'bottom'}
      closeOnBlur={true}
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <Button
          minWidth={'180px'}
          minHeight={'50px'}
          bg={'transparent'}
          cursor={'pointer'}
          paddingY={0}
          paddingRight={0}
        >
          <MenuPlaceholder isOpen={isOpen} isHeaderTransparent={isHeaderTransparent} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        width={'190px'}
        border={0}
        borderRadius={'8px'}
        boxShadow={'0px 6px 12px 0px #0E006224'}
      >
        <ProfileMenu
          onPressCallback={() => {
            onClose();
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

export default ProfileDropdownMenu;
