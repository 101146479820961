import CustomButton from '@/components/atoms/CustomButton';
import { Box, Center, HStack, Image, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import FilePicker from '@/components/atoms/FilePicker';
import { svgIconPaths } from '@/assets/svg/icons';
import ProfileBox from '../ProfileBox';
import logoVariants from '@/assets/img/logo';
import appMessages from '@/messages';
import ColorOverlay from '@/components/atoms/ColorOverlay';
import SvgIcon from '@/components/atoms/SvgIcon';

const PROFILE_IMG_PLACEHOLDER_SIZE = 200;

interface UserProfilePhotoBoxProps {
  setProfilePhoto: (photo: File) => void;
  initialPreviewUrl?: string;
  isLoading?: boolean;
}

const UserProfilePhotoBox = ({ setProfilePhoto, initialPreviewUrl = '', isLoading }: UserProfilePhotoBoxProps) => {
  const [showUploadButton, setShowUploadButton] = useState(false);
  const inputProfilePhotoRef = useRef<HTMLInputElement>(null);

  const [pendingPreview, setPendingPreview] = useState<string | null>(initialPreviewUrl);
  const [preview, setPreview] = useState<string | null>();

  useEffect(() => {
    if (!isLoading) setPreview(pendingPreview);
  }, [isLoading, pendingPreview]);

  useEffect(() => {
    return () => {
      if (pendingPreview) URL.revokeObjectURL(pendingPreview);
    };
  }, [pendingPreview]);

  const hasInitImagePreview = !!pendingPreview || !!initialPreviewUrl;

  const setSelectedFile = useCallback(async (file: File | null) => {
    if (!file) return;

    setProfilePhoto(file);
  }, []);

  return (
    <ProfileBox>
      <HStack justifyContent={'space-between'} width={'100%'} marginTop={'-5px'}>
        <Box maxWidth={'150px'}>
          <Text variant={'urbanistSemiBoldTitle'} textAlign={'start'} marginBottom={'10px'}>
            Profile Photo
          </Text>
          <Text variant={'urbanistSmall'} color={'text.mediumBlue'}>
            {appMessages.photoMaxSize}
          </Text>
        </Box>
        <Center
          borderRadius={'50%'}
          backgroundImage={preview ?? ''}
          height={'200px'}
          width={'200px'}
          backgroundPosition={'center'}
          backgroundRepeat={'no-repeat'}
          backgroundSize={'cover'}
          borderWidth={'5px'}
          borderColor={'white'}
          boxShadow={'0px 6px 12px 0px #0E006224'}
          onMouseEnter={() => setShowUploadButton(true)}
          onMouseLeave={() => setShowUploadButton(false)}
          position={'relative'}
          cursor={'pointer'}
          overflow={'hidden'}
          onClick={() => inputProfilePhotoRef.current?.click()}
        >
          {isLoading ? (
            <>
              <ColorOverlay color1Alpha={0.7} color2Alpha={0.7} />
              <Image
                animation={'alternate-opacity 0.7s infinite ease-out alternate'}
                src={logoVariants.whiteNoText160x160}
                width={'90px'}
                height={'92px'}
                alt="my-omnia-logo"
              />
            </>
          ) : (
            <Box position={'absolute'} opacity={showUploadButton ? 1 : 0} transition={'opacity 0.3s'}>
              <FilePicker
                inputRef={inputProfilePhotoRef}
                selectedFile={null}
                setSelectedFile={setSelectedFile}
                setPreview={setPendingPreview}
                accept="image/*"
                fileMinHeightPx={100}
                fileMinWidthPx={100}
              />
              <CustomButton
                // just sending in an empty function, because <Center> parent handles the click
                onClick={() => {}}
                label={'Upload'}
                backgroundColor="primary.600"
                hoverBackgroundColor="primary.600"
                labelHoverColor="white"
                labelColor="white"
                isLoading={isLoading}
              />
            </Box>
          )}

          {!hasInitImagePreview && (
            <Center
              backgroundColor={'primary.100'}
              height={PROFILE_IMG_PLACEHOLDER_SIZE}
              width={PROFILE_IMG_PLACEHOLDER_SIZE}
            >
              <SvgIcon color={'#fff'} iconPath={svgIconPaths.userSeeThrough} size={80} />
            </Center>
          )}
        </Center>
      </HStack>
    </ProfileBox>
  );
};

export default UserProfilePhotoBox;
