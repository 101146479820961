import authMessages from './auth';
import formRestrictions from './formRestrictions';
import toastMessages from './toast';

const appMessages = {
  ...authMessages,
  ...toastMessages,
  ...formRestrictions,
};

export default appMessages;
