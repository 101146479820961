import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/react';

const UserProfileBoxInfoPiece = ({ title, text, isLink }: { title: string; text: string; isLink?: boolean }) => {
  return (
    <Box gap={0}>
      <Text color={'background.blueGray'} variant={'urbanistExtraBoldSmall'} fontWeight="900">
        {title}
      </Text>
      {isLink ? (
        <a target="_blank" href={text} style={{ fontSize: '14px', fontWeight: 600, textDecoration: 'underline' }}>
          {text}
          <ExternalLinkIcon margin={'4px'} marginTop={'0'} />
        </a>
      ) : (
        <Text color={'text.darkBlue'} variant={'urbanistBold'} marginTop={'4px'} fontWeight={600} noOfLines={1}>
          {text}
        </Text>
      )}
    </Box>
  );
};

export default UserProfileBoxInfoPiece;
