import { extendTheme, type ThemeConfig } from '@chakra-ui/react';

import Text from './components/text';
import Button from './components/button';
import Input from './components/input';
import colors from './colors';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  breakpoints: {
    sm: '375px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    '2xl': '1440px',
    '3xl': '1920px',
  },
  components: {
    ...Text,
    ...Button,
    ...Input,
  },
  fonts: {
    heading: `'Lora', serif`,
    body: `'Urbanist', sans-serif`,
  },
  colors: {
    ...colors,
  },
});

export { theme };
