import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { redirect } from '@tanstack/react-router';
import { useAppStore } from '@/store/useAppStore';
import COOKIE_KEYS from '@/constants/cookies';

export type TCustomError = {
  message: string;
  code: number;
  name: string;
  payload: null;
};

export type TCustomAxiosError = {
  code: number;
  data: null;
  success: boolean;
  errors: TCustomError[];
};

export type TCustomAxiosResponse<T> = {
  code: number;
  success: boolean;
  errors: TCustomError[];
  data: T;
};

export type TCustomAxiosPaginationResponse<T> = TCustomAxiosResponse<T> & {
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    offset: number;
    totalItems: number;
  };
};

export type TPagination = {
  getFirstPage: () => void;
  getNextPage: () => void;
  getPreviousPage: () => void;
  getLastPage: () => void;
  currentPage: number;
  totalPages: number;
  totalItems: number;
};

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL as string,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Access Token Lifetime: 86400
// Implicit / Hybrid Token Lifetime: 7200

api.interceptors.response.use(
  (response) => response,
  (err: AxiosError) => {
    if (err.response) {
      const error = err?.response?.data as TCustomAxiosError;

      if (error.code === 401) {
        api.defaults.headers.Authorization = '';
        Cookies.remove(COOKIE_KEYS.ACCESS_TOKEN);

        const appStore = useAppStore.getState();
        appStore.reset();

        const message = error.errors[0]?.message;

        const isInvalidCredentials = message?.includes('credentials');

        if (!isInvalidCredentials) {
          redirect({
            to: '/sign-in',
            replace: true,
          });

          window.location.reload();
        }
      } else if (error.code === 404) {
        // Not found
      }

      return Promise.reject(err.response.data);
    }
  },
);

export { api };
