import LoadingPage from '@/components/templates/LoadingPage';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { Text, useDisclosure } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useLegalDocuments } from '@/hooks/legal/legal';
import CreateLegalDocumentModal from '@/modules/legal/CreateLegalDocumentModal/CreateLegalDocumentModal';
import { TLegalDocument } from '@/types/Legal.types';
import CustomTable from '@/components/molecules/CustomTable';
import { useMemo } from 'react';

const COLUMNS = ['Type', 'Version', 'Is Active', 'Created At'];
type TSortingColumn = (typeof COLUMNS)[number];

const EulaDocuments = () => {
  const { isOpen: isCreateModalOpen, onClose: onCreateModalClose, onOpen: onCreateModalOpen } = useDisclosure();

  const { isLoading, data: documents, pagination, isRefetching } = useLegalDocuments();

  const documentsTableData = documents?.map((document) => ({
    ...document,
    id: document._id,
  }));

  const data = { nodes: documentsTableData ?? [] };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item.id,
          cells: [
            item.type,
            <Text marginLeft={'10px'} fontWeight={'bold'}>
              {item.version}
            </Text>,
            <Text marginLeft={'12px'} fontWeight={'bold'}>
              {item.isActive ? 'Yes' : 'No'}
            </Text>,
            item.createdAt,
          ],
        };
      }),
    [data.nodes],
  );

  if (isLoading) return <LoadingPage />;

  return (
    <SidebarItemViewTemplate
      title={'EULA Documents'}
      buttonLabel="Create New Document"
      onButtonClick={onCreateModalOpen}
    >
      <CustomTable<TLegalDocument[], TSortingColumn>
        noItemsMessage="No Documents created yet"
        columns={COLUMNS}
        dataWithId={data.nodes}
        isLoading={isLoading}
        isRefetching={isRefetching}
        pagination={pagination}
        tableListItems={tableListItems}
        customColumnGridPercentages={[30, 20, 20, 30]}
        hasRowSelect
      />

      <CreateLegalDocumentModal isOpen={isCreateModalOpen} onClose={onCreateModalClose} />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/eula/')({
  component: EulaDocuments,
});
