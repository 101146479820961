import { HStack, Text, VStack } from '@chakra-ui/react';
import { format, formatDistance, intervalToDuration } from 'date-fns';
import React from 'react';

interface Props<T> {
  entity: T;
  createdBy?: string;
  canEdit: boolean;
  entityName: string;
  onClickEdit: (_: T) => void;
  onClickDelete: (_: T) => void;
}

const MemberCareMemberObservationNote = <T,>({
  entity,
  createdBy,
  canEdit,
  entityName,
  onClickEdit,
  onClickDelete,
}: Props<T & { createdAt: string; content: string }>) => {
  {
    const { days: distanceInDays, minutes: distanceInMinutes } = intervalToDuration({
      start: new Date(entity.createdAt),
      end: new Date(),
    });

    let formattedDate = format(new Date(entity.createdAt), 'dd. MMM h:mm a');

    if (!distanceInMinutes) {
      formattedDate = 'just now';
    }
    if (distanceInMinutes && !distanceInDays) {
      formattedDate = formatDistance(new Date(entity.createdAt), new Date(), { addSuffix: true });
    }
    if (distanceInDays && distanceInDays < 5) {
      formattedDate = `${formatDistance(new Date(entity.createdAt), new Date(), { addSuffix: true })} ${format(new Date(entity.createdAt), 'h:mm a')}`;
    }

    return (
      <VStack width={'100%'} gap={'5px'} data-group>
        {createdBy && (
          <HStack gap={'4px'} width={'100%'} justifyContent={'flex-start'} marginY={'-4px'}>
            <Text variant={'urbanistMediumRegular'} color="text.mediumBlue">
              Created by
            </Text>
            <Text variant={'urbanistMediumRegular'} color="primary.500">
              {createdBy}
            </Text>
          </HStack>
        )}
        <Text variant={'loraParagraph'} width={'100%'} textAlign={'start'} whiteSpace={'pre-wrap'}>
          {entity.content}
        </Text>
        <HStack width={'100%'} justifyContent={'space-between'}>
          <Text variant={'urbanistSmallBold'} color={'text.mediumGray'}>
            {formattedDate}
          </Text>
          {canEdit && (
            <HStack visibility={'hidden'} _groupHover={{ visibility: 'initial' }}>
              <Text
                color={'text.mediumBlue'}
                variant={'urbanistSmallBold'}
                cursor={'pointer'}
                _hover={{ color: 'extra.black' }}
                onClick={() => onClickEdit(entity)}
              >
                EDIT {entityName}
              </Text>
              <Text
                color={'text.mediumBlue'}
                variant={'urbanistSmallBold'}
                cursor={'pointer'}
                _hover={{ color: 'extra.darkRed' }}
                onClick={() => onClickDelete(entity)}
              >
                REMOVE {entityName}
              </Text>
            </HStack>
          )}
        </HStack>
      </VStack>
    );
  }
};

export default MemberCareMemberObservationNote;
