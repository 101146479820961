import CustomButton from '@/components/atoms/CustomButton';
import { Box, Center, HStack, Image, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import FilePicker from '@/components/atoms/FilePicker';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import appMessages from '@/messages';
import svgFiles from '@/assets/svg/svgFiles';

const PROFILE_IMG_PLACEHOLDER_SIZE = 100;

interface ProfilePhotoInputProps {
  setProfilePhoto: (photo: File) => void;
  initialPreviewUrl?: string;
}

const ProfilePhotoInput = ({ setProfilePhoto, initialPreviewUrl = '' }: ProfilePhotoInputProps) => {
  const [showUploadButton, setShowUploadButton] = useState(false);
  const inputProfilePhotoRef = useRef<HTMLInputElement>(null);

  const [preview, setPreview] = useState<string | null>(initialPreviewUrl);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const hasInitImagePreview = !!preview || !!initialPreviewUrl;

  const uploadPhotoWithLoading = useCallback(async (file: File | null) => {
    if (!file) return;

    setProfilePhoto(file);
  }, []);

  return (
    <Box
      height="130px"
      width={FORM_FIELD_WIDTH}
      padding={'20px'}
      boxShadow="0px 10px 80px 0px #00417926"
      background="white"
      borderRadius={'8px'}
      marginBottom={'10px'}
    >
      <Center>
        <HStack justifyContent={'space-between'} width={'100%'} marginTop={'-5px'}>
          <Box maxWidth={'150px'}>
            <Text variant={'urbanistSemiBold'} textAlign={'start'} marginBottom={'10px'}>
              Profile Photo
            </Text>
            <Text variant={'urbanistSmall'} color={'text.mediumBlue'}>
              {appMessages.photoMaxSize}
            </Text>
          </Box>
          <Center
            borderRadius={'50%'}
            backgroundImage={preview ?? ''}
            height={PROFILE_IMG_PLACEHOLDER_SIZE}
            width={PROFILE_IMG_PLACEHOLDER_SIZE}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            backgroundSize={'cover'}
            borderWidth={'4px'}
            borderColor={'white'}
            boxShadow={'0px 12px 30px 0px #0E00621A'}
            onMouseEnter={() => setShowUploadButton(true)}
            onMouseLeave={() => setShowUploadButton(false)}
            position={'relative'}
            cursor={'pointer'}
            onClick={() => inputProfilePhotoRef.current?.click()}
          >
            <Box position={'absolute'} opacity={showUploadButton ? 1 : 0} transition={'opacity 0.3s'}>
              <FilePicker
                inputRef={inputProfilePhotoRef}
                selectedFile={null}
                setSelectedFile={uploadPhotoWithLoading}
                setPreview={setPreview}
                accept="image/*"
                fileMinHeightPx={100}
                fileMinWidthPx={100}
              />
              <CustomButton
                // just sending in an empty function, because <Center> parent handles the click
                onClick={() => {}}
                label={'Upload'}
                backgroundColor="primary.600"
                hoverBackgroundColor="primary.600"
                labelHoverColor="white"
                labelColor="white"
              />
            </Box>

            {!hasInitImagePreview && (
              <Center
                backgroundColor={'white'}
                height={PROFILE_IMG_PLACEHOLDER_SIZE}
                width={PROFILE_IMG_PLACEHOLDER_SIZE}
                borderRadius={'50%'}
              >
                <Image
                  objectFit={'cover'}
                  src={svgFiles.genericUser}
                  alt="profile-image"
                  width={PROFILE_IMG_PLACEHOLDER_SIZE}
                  height={PROFILE_IMG_PLACEHOLDER_SIZE}
                  style={{
                    borderRadius: '50%',
                    border: '4px solid white',
                    minHeight: PROFILE_IMG_PLACEHOLDER_SIZE,
                    minWidth: PROFILE_IMG_PLACEHOLDER_SIZE,
                    background: 'white',
                    boxShadow: '0px 6px 12px 0px #0E006224',
                  }}
                />
              </Center>
            )}
          </Center>
        </HStack>
      </Center>
    </Box>
  );
};

export default ProfilePhotoInput;
