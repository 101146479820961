const queryKeys = {
  users: { activatedUsers: 'activated-users', deactivatedUsers: 'deactivated-users' },
  assessment: { allAssessments: 'all-assessments' },
  organization: {
    allOrganizations: 'all-organizations',
    memberCareSpecialists: 'org-member-care-specialists',
    organizationRoster: 'organization-roster',
  },
  legalDocument: { allLegalDocuments: 'all-legal-documents' },
  memberCare: {
    memberCareSpecialistOwnProfile: 'member-care-own-profile',
    memberCareSpecialists: 'member-care-specialists',
    memberCareSpecialistById: 'member-care-specialist-by-id',
    memberCaresMemberById: 'member-cares-member-by-id',
    memberCaresMemberTimeline: 'member-cares-member-timeline',
    memberCaresMemberObservations: 'member-cares-member-observations',
    memberCaresMemberNotes: 'member-cares-member-notes',
    assignedOrgs: 'member-care-assigned-orgs',
    unassignedOrgs: 'member-care-unassigned-orgs',
    memberCareMembers: 'member-care-members',
    memberCareOrganizations: 'member-care-orgs',
    memberCareOrganizationDashboard: 'member-care-org-dashboard',
    memberCareOrganizationRoster: 'member-care-org-roster',
    inviteToPlatform: 'member-care-invite-to-platform',
  },
};

export default queryKeys;
