import { TCustomAxiosPaginationResponse, TPagination } from '@/services/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

interface IUsePaginationParams<TData, TParams = void> {
  query: (
    page: number,
    queryParams?: TParams,
    sortingColumn?: string,
    sortingDirection?: string,
  ) => Promise<TCustomAxiosPaginationResponse<TData>>;
  queryKey: string | string[];
  sortingColumn?: string;
  sortingDirection?: string;
  queryParams?: TParams;
}

/**
 *
 * @param query - the query to be executed. Must receive `page: number` param and return ApiResponseType<T>
 * @returns isLoading: boolean; isSuccess: boolean; data: TData | undefined; isError: boolean; error: Error | null; pagination: TPagination
 */
export const usePagination = <TData, TParam = void>({
  query,
  queryKey,
  sortingColumn,
  sortingDirection,
  queryParams,
}: IUsePaginationParams<TData, TParam>) => {
  const [page, setPage] = useState(1);

  const { isLoading, isSuccess, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKey, page, sortingColumn, sortingDirection, queryParams],
    queryFn: async () => {
      const queryToCall = query(page, queryParams, sortingColumn, sortingDirection);
      const response = await queryToCall;
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const lastPage = data?.pagination.totalPages ?? 1;

  const getFirstPage = () => setPage(1);
  const getLastPage = () => setPage(lastPage);
  const getNextPage = () => setPage((prev) => (prev < lastPage ? prev + 1 : lastPage));
  const getPreviousPage = () => setPage((prev) => (prev > 1 ? prev - 1 : 1));

  return {
    isLoading,
    isSuccess,
    data: data?.data,
    isError,
    error,
    refetch,
    isRefetching,
    pagination: {
      getFirstPage,
      getNextPage,
      getPreviousPage,
      getLastPage,
      totalPages: data?.pagination.totalPages ?? 0,
      currentPage: page,
      totalItems: data?.pagination.totalItems,
    } as TPagination,
  };
};
