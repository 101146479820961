import LoadingLogo from '@/components/atoms/LoadingLogo';
import { Flex } from '@chakra-ui/react';

const LoadingPage = ({ width = '100vw', height = '100vh' }) => {
  return (
    <Flex
      width={width}
      height={height}
      flexDirection="column"
      alignItems={'center'}
      justifyContent={'center'}
      top={0}
      left={0}
      pos={'absolute'}
    >
      <LoadingLogo />
    </Flex>
  );
};

export default LoadingPage;
