const rootPath = '/img/logo';

const logoVariants = {
  white252x80: `${rootPath}/my-omnia-white-252x80.png`,
  purple252x80: `${rootPath}/my-omnia-purple-252x80.png`,
  whiteNoText160x160: `${rootPath}/my-omnia-no-text-160x160.png`,
  purpleNoText112x112: `${rootPath}/my-omnia-no-text-0.5-112x112.png`,
  purpleMemberCare229x40: `${rootPath}/my-omnia-member-care-229x40.png`,
};

export default logoVariants;
