import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { Center, HStack } from '@chakra-ui/react';
import { useRouter } from '@tanstack/react-router';
import CustomButton from '../CustomButton';
import { svgIconPaths } from '@/assets/svg/icons';
import colors from '@/theme/colors';

const GoBackLeftArrow = () => {
  const router = useRouter();

  return (
    <Center position={'absolute'} height={'50px'}>
      <HStack width={MAX_CONTAINER_WIDTH} paddingLeft={'14px'}>
        <CustomButton
          isTransparent
          iconBeforeLabelSvgPath={svgIconPaths.leftArrow}
          iconBeforeLabelSvgColor={colors.text.mediumGray}
          iconBeforeLabelSvgSize={15}
          labelHoverColor="extra.black"
          labelColor="text.mediumGray"
          label="Go Back"
          borderHoverColor="transparent"
          onClick={router.history.back}
          style={{ paddingLeft: 0 }}
          labelStyle={{ marginBottom: 2, fontWeight: 700, fontSize: '16px' }}
        />
      </HStack>
    </Center>
  );
};

export default GoBackLeftArrow;
