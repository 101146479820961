import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import ModalContainer from '../ModalContainer/ModalContainer';
import ConfirmCancelButtons, { ConfirmCancelButtonProps } from './ConfirmCancelButtons';

export interface ConfirmationModalProps extends ConfirmCancelButtonProps {
  title: string;
  description: string;
  isOpen: boolean;
}

const ConfirmationModal = ({
  title,
  description,
  isOpen,
  onClose,
  onConfirm,
  confirmButtonText,
  confirmButtonColor = 'primary.500',
  confirmLoading = false,
}: ConfirmationModalProps) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      height={'20vh'}
      width="35%"
      contentStyle={{ padding: '20px' }}
      footerContent={
        <ConfirmCancelButtons
          confirmButtonText={confirmButtonText}
          confirmButtonColor={confirmButtonColor}
          onClose={onClose}
          onConfirm={onConfirm}
          confirmLoading={confirmLoading}
        />
      }
    >
      <VStack marginTop={'10px'} marginBottom={'-10px'}>
        <Text variant={'loraSmallTitle'} color={'black'} textAlign={'center'}>
          {title}
        </Text>
        <Text fontSize={'20px'} marginTop={'20px'} textAlign={'center'} whiteSpace={'pre-wrap'} width={'100%'}>
          {description}
        </Text>
      </VStack>
    </ModalContainer>
  );
};

export default ConfirmationModal;
