import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import CircleFillOutWithText from '../WellnessStateCircle/CircleFillOutWithText';

interface Props {
  circlePercentageFilled: number;
  circleTitle: string | number;
  circleSubtitle: JSX.Element | string;
  textTitle: string;
  textSubtitle: string;
}

const StatisticsItemWithCircleText = ({
  circlePercentageFilled,
  circleTitle,
  circleSubtitle,
  textTitle,
  textSubtitle,
}: Props) => {
  return (
    <HStack width={'100%'}>
      <CircleFillOutWithText
        circleDiameter={80}
        thickness={7}
        centeredElement={
          <VStack gap={0}>
            <Text variant={'urbanistExtraBold'} fontSize={'16px'} color={'text.darkBlue'}>
              {circleTitle}
            </Text>
            {circleSubtitle}
          </VStack>
        }
        percentageTextStyle={{ display: 'none' }}
        percentageFilled={circlePercentageFilled}
        svgId={`svg-statistics-${circleTitle}-${circlePercentageFilled.toFixed()}`}
      />
      <VStack marginLeft={'25px'} justifyContent={'flex-start'} gap={'5px'}>
        <Text variant={'loraMedium'} fontStyle={'italic'} color={'text.darkBlue'}>
          {textTitle}
        </Text>
        <Text variant={'urbanistExtraBold'} color={'text.mediumGray'} width={'100%'}>
          {textSubtitle}
        </Text>
      </VStack>
    </HStack>
  );
};

export default StatisticsItemWithCircleText;
