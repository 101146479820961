import React from 'react';
import { Input, Text, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { EMAIL_REGEX } from '@/constants/stringVars';
import { useInviteOrganizationAdmin } from '@/hooks/organization/organization';
import appRoutes from '@/constants/routes/app';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import CustomButton from '@/components/atoms/CustomButton';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  organizationId: string;
};

const InviteOrganizationAdminModal = ({ isOpen, onClose, organizationId }: Props) => {
  const queryClient = useQueryClient();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup
        .object()
        .shape({
          firstName: yup
            .string()
            .trim()
            .required('First name is required.')
            .min(1, 'First name needs to be at least 1 character long.'),
        })
        .shape({
          lastName: yup
            .string()
            .trim()
            .required('Last name is required.')
            .min(1, 'Last name needs to be at least 1 character long.'),
        })
        .shape({
          email: yup.string().required('Email is required.').matches(EMAIL_REGEX, 'Wrong email format.'),
        }),
    ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = methods;

  const { mutate, isPending } = useInviteOrganizationAdmin(organizationId, () => {
    queryClient.invalidateQueries({ queryKey: [appRoutes.organizations] });
    reset();
    onClose?.();
  });

  const onSubmit = ({ firstName, lastName, email }: { firstName: string; lastName: string; email: string }) => {
    mutate({ firstName, lastName, email });
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} title="Invite Organization Admin">
      <VStack>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <VStack spacing={'20px'} width={'100%'}>
              <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
                <Input placeholder="First Name" {...register('firstName')} />
                <Text color={'red.500'} fontSize={'14px'}>
                  {errors.firstName?.message ?? ''}
                </Text>
              </VStack>
              <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
                <Input placeholder="Last Name" {...register('lastName')} />
                <Text color={'red.500'} fontSize={'14px'}>
                  {errors.lastName?.message ?? ''}
                </Text>
              </VStack>
              <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
                <Input placeholder="Email" {...register('email')} />
                <Text color={'red.500'} fontSize={'14px'}>
                  {errors.email?.message ?? ''}
                </Text>
              </VStack>
              <CustomButton label="Send Invitation" buttonType="submit" disabled={!isValid} isLoading={isPending} />
            </VStack>
          </form>
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};

export default InviteOrganizationAdminModal;
