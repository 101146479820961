import { TOrganization, TOrganizationAdminListItem, TOrganizationSuperAdminExtended } from '@/types/Organization.types';
import { api, TCustomAxiosPaginationResponse } from '..';
import { TAdminOrganizationsSortingColumn } from '@/routes/_authenticated/_admin/organizations';
import { ITEMS_PER_PAGE } from '@/theme/components/table';
import { TUser } from '@/types/User.types';

const root = 'v1/organization';

const endpoints = {
  organization: `${root}`,
  inviteUsers: `${root}/invite/users`,
};

export const getAllOrganizations = async (
  page: number,
  filterParams?: { searchText: string },
  sortingColumn?: string,
  sortingDirection?: string,
): Promise<TCustomAxiosPaginationResponse<TOrganizationAdminListItem[]>> => {
  let sortBy = '';
  switch (sortingColumn as TAdminOrganizationsSortingColumn) {
    case 'Representative Email':
      sortBy = 'representativeEmail';
      break;
    case 'Total # of Employees':
      sortBy = 'totalUsersCount';
      break;
    case '# of Onboarded':
      sortBy = 'onboardedUsersCount';
      break;
    case '# of Member Cares':
      sortBy = 'assignedMemberCaresCount';
      break;

    case 'Name':
    default:
      sortBy = 'name';
      break;
  }

  const params: { limit: number; page: number; sortBy: string; order: string | undefined; search?: string } = {
    limit: ITEMS_PER_PAGE,
    page,
    sortBy,
    order: sortingDirection,
  };

  if (filterParams?.searchText) params.search = filterParams?.searchText;

  const { data } = await api.get(endpoints.organization, {
    params,
  });

  return data;
};

export const getOrganizationRoster = async (
  id: string,
  page: number,
  activeUsers: boolean,
  filterParams?: { searchText: string },
): Promise<TCustomAxiosPaginationResponse<TUser[]>> => {
  const params: { limit: number; page: number; search?: string } = {
    limit: ITEMS_PER_PAGE,
    page,
  };

  if (filterParams?.searchText) params.search = filterParams?.searchText;

  const { data } = await api.get<TCustomAxiosPaginationResponse<TUser[]>>(`${endpoints.organization}/${id}/users`, {
    params: {
      ...params,
      activeUsers,
    },
  });

  return data;
};

export const organizationByIdGet = async ({ id }: { id: string }): Promise<TOrganizationSuperAdminExtended> => {
  const { data } = await api.get(`${endpoints.organization}/${id}`);

  const organization: TOrganizationSuperAdminExtended = data.data;

  return organization;
};

export const createOrganizationPost = async ({ name }: { name: string }): Promise<TOrganization> => {
  const { data } = await api.post(endpoints.organization, {
    name,
  });

  const organization = data.data;

  return organization;
};

export const updateOrganizationByIdPut = async (organizationId: string, orgInfo: TOrganization) => {
  const { data } = await api.put<TCustomAxiosPaginationResponse<TOrganization>>(
    `${endpoints.organization}/${organizationId}`,
    {
      ...orgInfo,
    },
  );
  const updatedOrganization = data.data;
  return updatedOrganization;
};

export const inviteUsersToOrganizationPost = async (
  users: { email: string; phone: string; firstName: string; lastName: string }[],
) => {
  const { data } = await api.post<TCustomAxiosPaginationResponse<unknown>>(endpoints.inviteUsers, {
    users,
  });

  return data.data;
};

export const inviteOrganizationAdminPost = async ({
  organizationId,
  firstName,
  lastName,
  email,
}: {
  organizationId: string;
  firstName: string;
  lastName: string;
  email: string;
}): Promise<string> => {
  const { data } = await api.post(`${endpoints.organization}/${organizationId}/invite-admin`, {
    firstName,
    lastName,
    email,
  });

  const message: string = data.data;

  return message;
};
