import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIcon from '@/components/atoms/SvgIcon';
import { BUTTON_BOX_SHADOW_STYLE } from '@/constants/ui';
import colors from '@/theme/colors';
import {
  Button,
  Center,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { CSSProperties } from 'react';

interface Props {
  popoverContent: JSX.Element;
  isFilterActive: boolean;
  onFilterSubmit: () => void;
  onFilterReset: () => void;
  submitButtonDisabled: boolean;
  filterResetLabel?: string;
  contentWidth?: string;
  filterIconPath?: string;
  filterIconText?: string;
  filterIconStyle?: CSSProperties;
}

const FilterSelectPopover = ({
  popoverContent,
  isFilterActive,
  onFilterSubmit,
  onFilterReset,
  submitButtonDisabled,
  contentWidth = '360px',
  filterIconPath = svgIconPaths.filter,
  filterIconText = 'Filter',
  filterIconStyle,
}: Props) => {
  const { isOpen: isFilterOpen, onOpen, onClose } = useDisclosure();

  const localFilterSubmit = () => {
    onFilterSubmit();
    onClose();
  };

  const localFilterReset = () => {
    onFilterReset();
    onClose();
  };

  return (
    <VStack position={'relative'}>
      {isFilterActive && (
        <Center
          position={'absolute'}
          right={'-10px'}
          top={'-10px'}
          zIndex={2}
          backgroundColor={'extra.lightRed'}
          borderRadius={'full'}
          width={'22px'}
          height={'22px'}
          border={`4px solid ${colors.text.veryLightBlue}`}
        ></Center>
      )}
      <Popover placement="bottom" isOpen={isFilterOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button
            width={'100px'}
            height={'54px'}
            cursor="pointer"
            _hover={{ backgroundColor: 'white', boxShadow: BUTTON_BOX_SHADOW_STYLE }}
            background={isFilterOpen || isFilterActive ? 'white' : 'transparent'}
            paddingY={'10px'}
            paddingX={'16px'}
            borderRadius={'8px'}
            boxShadow={isFilterOpen || isFilterActive ? BUTTON_BOX_SHADOW_STYLE : 'none'}
          >
            <HStack gap={'2px'}>
              <SvgIcon
                iconPath={filterIconPath}
                color={colors.text.darkBlue}
                size={14}
                style={{ opacity: isFilterOpen ? 0.5 : 1, ...filterIconStyle }}
              />
              <VStack flexGrow={1} gap={'3px'} alignItems={'flex-start'} marginLeft={'10px'}>
                <Text
                  fontSize={'14px'}
                  lineHeight={'16px'}
                  fontWeight={'700'}
                  opacity={isFilterOpen ? 0.5 : 1}
                  color={'text.darkBlue'}
                >
                  {filterIconText}
                </Text>
              </VStack>
            </HStack>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          borderRadius={'8px'}
          borderColor={'transparent'}
          width={contentWidth}
          boxShadow={BUTTON_BOX_SHADOW_STYLE}
        >
          <VStack bg="white" borderRadius={'8px'} padding={'20px'}>
            {popoverContent}
            <CustomButton
              label="Apply"
              disabled={submitButtonDisabled}
              width={'100%'}
              height={'54px'}
              onClick={localFilterSubmit}
            />
            {isFilterActive && (
              <CustomButton
                isTransparent
                onClick={localFilterReset}
                label={`Reset ${filterIconText}`}
                width={'100%'}
                height={'40px'}
                labelColor="text.mediumBlue"
                labelHoverColor="extra.black"
              />
            )}
          </VStack>
        </PopoverContent>
      </Popover>
    </VStack>
  );
};

export default FilterSelectPopover;
