import { HStack } from '@chakra-ui/react';
import React from 'react';
import ProfileDropdownMenu from '../../ProfileDropdownMenu';

interface Props {
  isTransparent: boolean;
}

const DefaultHeader = ({ isTransparent }: Props) => {
  return (
    <HStack>
      <ProfileDropdownMenu isHeaderTransparent={isTransparent} />
    </HStack>
  );
};

export default DefaultHeader;
