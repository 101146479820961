import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import CustomTable from '@/components/molecules/CustomTable';
import LoadingPage from '@/components/templates/LoadingPage';
import appRoutes from '@/constants/routes/app';
import { useMemberCareSpecialistsForOrganizations } from '@/hooks/member-care/memberCare';
import { useOrganizationById } from '@/hooks/organization/organization';
import InviteOrganizationAdminModal from '@/modules/organization/InviteOrganizationAdminModal';
import colors from '@/theme/colors';
import { TMemberCare } from '@/types/MemberCare.types';
import { Box, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useMemo } from 'react';

const COLUMNS: string[] = ['First Name', 'Last Name'];
type TSortingColumn = (typeof COLUMNS)[number];

const OrganizationPage = () => {
  const { organizationId } = Route.useParams();
  const navigate = useNavigate();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { isLoading, isSuccess, data: organizationData } = useOrganizationById(organizationId);

  const {
    isLoading: isLoadingOrgMemberCareSpecialists,
    data: organizationMemberCareSpecialists,
    isRefetching,
    pagination,
  } = useMemberCareSpecialistsForOrganizations(organizationId);

  const organizationMemberCareSpecialistsTableData = organizationMemberCareSpecialists?.map((memberCareSpecialist) => ({
    ...memberCareSpecialist,
    id: memberCareSpecialist.user_id,
  }));

  const data = { nodes: organizationMemberCareSpecialistsTableData ?? [] };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item.user_id,
          cells: [
            item.firstName ?? item.user_metadata?.firstName ?? '',
            item.lastName ?? item.user_metadata?.lastName ?? '',
          ],
        };
      }),
    [data.nodes],
  );

  if (isLoading || isLoadingOrgMemberCareSpecialists) return <LoadingPage />;

  return (
    <VStack width={'100%'}>
      <HStack justifyContent={'space-between'} width={'100%'}>
        <Text variant="loraTitle">{organizationData?.name}</Text>
        <CustomButton label="Invite Organization Admin" onClick={onOpen} />
      </HStack>
      <HStack justify={'flex-start'} width={'100%'} marginTop={'20px'}>
        <CustomButton
          isTransparent
          iconBeforeLabelSvgPath={svgIconPaths.leftArrow}
          labelColor={colors.background.blueGray}
          labelHoverColor="extra.black"
          label="Back to Organizations"
          hoverBackgroundColor="transparent"
          borderHoverColor="transparent"
          onClick={() => navigate({ to: appRoutes.organizations })}
          labelStyle={{ marginBottom: '5px', fontSize: '16px' }}
        />
      </HStack>
      <Box marginTop={'20px'} marginBottom={'40px'} width={'50%'}>
        {!isLoading && isSuccess && (
          <VStack
            paddingX={'40px'}
            paddingY={'20px'}
            bg="white"
            borderRadius={'10px'}
            boxShadow={'0px 10px 80px 0px primary.whiteOptionsShadow'}
          >
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Name:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.name}
              </Text>
            </HStack>
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Website:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.website ?? '/'}
              </Text>
            </HStack>
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Representative:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.representativeFullName ?? '/'}
              </Text>
            </HStack>
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Representative Email:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.representativeEmail ?? '/'}
              </Text>
            </HStack>
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Representative Phone:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.representativePhoneNumber ?? '/'}
              </Text>
            </HStack>
            <HStack paddingY={'10px'} justifyContent={'space-between'} width={'100%'}>
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Members Count:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.members.length ?? '/'}
              </Text>
            </HStack>
          </VStack>
        )}
      </Box>
      <Text variant={'loraSmallTitle'}>Member Support Specialists</Text>
      <CustomTable<TMemberCare[], TSortingColumn>
        isLoading={isLoadingOrgMemberCareSpecialists}
        dataWithId={data.nodes}
        isRefetching={isRefetching}
        pagination={pagination}
        tableListItems={tableListItems}
        isSearching={false}
        noItemsMessage="No Member Support Specialists"
        columns={COLUMNS}
        tableWidthPercentage="50%"
      />
      <InviteOrganizationAdminModal isOpen={isOpen} onClose={onClose} organizationId={organizationId} />
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/organizations/$organizationId')({
  component: OrganizationPage,
});
