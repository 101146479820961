import { FORM_FIELD_WIDTH, HEADER_HEIGHT } from '@/constants/dimensions';
import onboardingMessages from '@/messages/onboarding';
import { TMemberCareOnboarding } from '@/types/MemberCare.types';
import { Box, Image, Input, InputGroup, InputRightElement, Stack, Text, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CALENDLY_LINK_PLACEHOLDER, SEX_OPTIONS } from '@/constants/stringVars';
import { useAppStore } from '@/store/useAppStore';
import Dropdown from '@/components/atoms/Dropdown';
import CustomButton from '@/components/atoms/CustomButton';
import { useState } from 'react';
import iconsPng from '@/assets/img/png/icons';
import BackgroundImagePage from '@/components/templates/BackgroundImagePage';
import useOnboardMemberCare from '@/hooks/onboarding/useOnboardMemberCare';
import ProfilePhotoInput from '@/components/molecules/ProfilePhotoInput';
import { MAX_CHARS_IN_BIO, MAX_CHARS_IN_PHONE, MIN_CHARS_IN_BIO, MIN_CHARS_IN_PHONE } from '@/constants/numberVars';
import { getMemberCareSpecialistOwnProfile } from '@/services/api/requests/member-care';

const memberCareOnboardingSchema = yup.object().shape({
  email: yup.string().required(),
  firstName: yup
    .string()
    .required('First Name is required.')
    .matches(/^[a-zA-Z ]+$/, 'Only alphabetic characters allowed'),
  lastName: yup
    .string()
    .required('Last Name is required.')
    .matches(/^[a-zA-Z ]+$/, 'Only alphabetic characters allowed'),
  sex: yup.string().oneOf(SEX_OPTIONS).required('Sex is required.'),
  phone: yup
    .string()
    .required('Phone number is required.')
    .min(MIN_CHARS_IN_PHONE, `Minimum ${MIN_CHARS_IN_PHONE} characters are required in the Phone`)
    .max(MAX_CHARS_IN_PHONE, `Maximum ${MAX_CHARS_IN_PHONE} characters allowed in the Phone`),
  bio: yup
    .string()
    .required('Bio is required')
    .min(MIN_CHARS_IN_BIO, `Minimum ${MIN_CHARS_IN_BIO} characters are required in the Bio`)
    .max(MAX_CHARS_IN_BIO, `Maximum ${MAX_CHARS_IN_BIO} characters allowed in the Bio`),
  calendly_link: yup
    .string()
    .required('Calendly Link is required')
    .url('Invalid URL format')
    .max(100, 'Maximum 100 characters allowed in the Calendly Link')
    .test('calendly', 'Must be a valid Calendly Link', (val) => val?.toLowerCase().includes('calendly.com')),
});

const OnboardingMemberCare = () => {
  const { user, setUser, setMemberCare } = useAppStore();
  const navigate = useNavigate();

  const {
    register,
    setValue,
    formState: { isValid, errors },
    handleSubmit,
    watch,
  } = useForm<TMemberCareOnboarding>({
    resolver: yupResolver(memberCareOnboardingSchema),
    mode: 'all',
    defaultValues: {
      email: user?.email || '',
      firstName: user?.firstName || user?.user_metadata.firstName,
      lastName: user?.lastName || user?.user_metadata.lastName,
    },
  });

  const sexValue = watch('sex');

  const [profilePhotoFile, setProfilePhotoFile] = useState<File | null>(null);

  const { mutate, isPending, isError } = useOnboardMemberCare(() => {
    if (sexValue) {
      if (user) {
        // update current user's `sex` because that's how we know they finished onboarding
        setUser({ ...user, user_metadata: { ...user.user_metadata, sex: sexValue } });

        // update memberCare in the appStore
        getMemberCareSpecialistOwnProfile().then((data) => setMemberCare(data.data));
      }
      navigate({ to: '/member-care/members' });
    }
  });

  const onFormSubmit = (data: TMemberCareOnboarding) => {
    if (profilePhotoFile) {
      mutate({ ...data, profilePhoto: profilePhotoFile });
    } else {
      mutate(data);
    }
  };

  return (
    <BackgroundImagePage title={onboardingMessages.memberCareOnboardingTitle} heroChildrenTopMargin={HEADER_HEIGHT}>
      <Stack alignItems={'center'} paddingBottom={'50px'}>
        <Text
          variant={'urbanistSemiBold'}
          color={'text.veryLightBlue'}
          opacity={0.7}
          position={'relative'}
          marginTop={'16px'}
          maxWidth={'400px'}
        >
          {onboardingMessages.memberCareOnboardingDescription}
        </Text>
        <Box maxW={{ md: FORM_FIELD_WIDTH }} minW={{ md: FORM_FIELD_WIDTH }} marginTop={'24px'}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Stack>
              <VStack
                gap={0}
                backgroundColor={'white'}
                borderRadius={'8px'}
                paddingX={'16px'}
                cursor={'not-allowed'}
                marginBottom={'10px'}
              >
                <Text
                  color={'text.mediumGray'}
                  fontWeight={900}
                  lineHeight={'12px'}
                  fontSize={'10px'}
                  paddingTop={'12px'}
                  width={'100%'}
                  textAlign={'start'}
                >
                  {onboardingMessages.yourEmailAddress.toUpperCase()}
                </Text>
                <InputGroup>
                  <Input
                    {...register('email', { required: true })}
                    disabled={true} // disabling this always; because we are only coming to this page with an existing email
                    _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                    maxHeight={'22px'}
                    lineHeight={'18px'}
                    borderWidth={'0'}
                    fontSize={'14px'}
                    padding={'0px'}
                    marginBottom={'8px'}
                    fontWeight={600}
                    color={'text.mediumGray'}
                  />
                  <InputRightElement width="2rem" height={'100%'} marginTop={'-12px'}>
                    <Image alt="lock" src={iconsPng.lock} width={25} height={25} />
                  </InputRightElement>
                </InputGroup>
              </VStack>

              <ProfilePhotoInput setProfilePhoto={setProfilePhotoFile} />

              <Stack>
                <Input
                  {...register('firstName', { required: true })}
                  className="custom-input"
                  placeholder={'First Name'}
                  _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                />
                <Text variant={'errorLightText'} textAlign={'left'}>
                  {errors.firstName?.message ?? ''}
                </Text>
              </Stack>
              <Stack>
                <Input
                  {...register('lastName', { required: true })}
                  className="custom-input"
                  placeholder={'Last Name'}
                  _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                />
                <Text variant={'errorLightText'} textAlign={'left'}>
                  {errors.lastName?.message ?? ''}
                </Text>
              </Stack>
              <Stack>
                <Dropdown
                  title="Sex"
                  height={'50px'}
                  popoverWidth={FORM_FIELD_WIDTH}
                  selectedOption={sexValue}
                  onOptionClick={(option) => setValue('sex', option)}
                  options={SEX_OPTIONS}
                />
                <Text variant={'errorLightText'} textAlign={'left'}>
                  {(!sexValue && errors.sex?.message) ?? ''}
                </Text>
              </Stack>
              <Stack>
                <Input
                  {...register('phone', { required: true })}
                  className="custom-input"
                  placeholder={'Phone Number'}
                  _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                />
                <Text variant={'errorLightText'} textAlign={'left'}>
                  {errors.phone?.message ?? ''}
                </Text>
              </Stack>
              <Stack>
                <Input
                  {...register('bio')}
                  className="custom-input"
                  as={'textarea'}
                  placeholder={'Bio'}
                  style={{ minHeight: '100px', maxHeight: '200px' }}
                  _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                />
                <Text variant={'errorLightText'} textAlign={'left'}>
                  {errors.bio?.message ?? ''}
                </Text>
              </Stack>
              <Stack>
                <Input
                  {...register('calendly_link')}
                  className="custom-input"
                  placeholder={CALENDLY_LINK_PLACEHOLDER}
                  _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                />
                <Text variant={'errorLightText'} textAlign={'left'}>
                  {errors.calendly_link?.message ?? ''}
                </Text>
              </Stack>
              <CustomButton
                label="Sign Up"
                buttonType="submit"
                width="full"
                backgroundColor="secondary.500"
                style={{ marginTop: '40px' }}
                disabled={!isValid}
                isLoading={isPending}
              />
              <Text variant={'errorLightText'} textAlign={'center'}>
                {isError ? 'Something went wrong' : ''}
              </Text>
            </Stack>
          </form>
        </Box>
      </Stack>
    </BackgroundImagePage>
  );
};

export const Route = createFileRoute('/_authenticated/_onboarding/onboarding/member-care')({
  component: OnboardingMemberCare,
});
