import { TTimeline } from '@/types/Timeline.types.ts';
import { api, TCustomAxiosResponse } from '..';

const root = 'v1/timeline';

const endpoints = {
  timeline: `${root}`,
  user: `${root}/user`,
};

export const getTimelineForMemberCareMember = async (memberId: string): Promise<TCustomAxiosResponse<TTimeline[]>> => {
  const { data } = await api.get<TCustomAxiosResponse<TTimeline[]>>(`${endpoints.user}/${memberId}`);
  const timelineItems = data;

  return timelineItems;
};
