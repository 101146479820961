import CustomButton from '@/components/atoms/CustomButton';
import GoBackLeftArrow from '@/components/atoms/GoBackLeftArrow';
import TopRightBackgroundCircle from '@/components/atoms/TopRightBackgroundCircle';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import { MAX_CHARS_IN_BIO, MIN_CHARS_IN_BIO } from '@/constants/numberVars';
import { useUpdateMemberCareBio } from '@/hooks/member-care/memberCare';
import { useAppStore } from '@/store/useAppStore';
import { Input, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yupResolver(
  yup.object().shape({
    bio: yup
      .string()
      .required('Bio is required')
      .min(MIN_CHARS_IN_BIO, `Minimum ${MIN_CHARS_IN_BIO} characters are required in the Bio`)
      .max(MAX_CHARS_IN_BIO, `Maximum ${MAX_CHARS_IN_BIO} characters allowed in the Bio`),
  }),
);

const ProfileBio = () => {
  const router = useRouter();
  const { mutate: updateMemberCareBio, isPending } = useUpdateMemberCareBio(() => router.history.back());
  const { memberCare } = useAppStore();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      bio: (memberCare?.user_metadata?.bio) ?? '',
    },
    resolver: schema,
  });

  const { handleSubmit, getValues, register, formState, watch } = methods;

  const bio = watch('bio');

  const { errors, isValid } = formState;

  const changesMade = bio !== memberCare?.user_metadata?.bio;

  const submitDisabled = !isValid || !changesMade;

  const onSubmit = () => {
    const { bio } = getValues();
    updateMemberCareBio(bio);
  };

  return (
    <AppPageTemplate>
      <TopRightBackgroundCircle />
      <GoBackLeftArrow />
      <Text variant={'loraSmallTitle'}>Edit Your Bio</Text>
      {/* TODO... */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack marginY={'60px'}>
            <Input
              {...register('bio', { required: true })}
              className="custom-input"
              as={'textarea'}
              placeholder={'Bio'}
              minWidth={FORM_FIELD_WIDTH}
              style={{ minHeight: '200px', maxHeight: '400px' }}
              _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
            />
            <Text variant={'error'} textAlign={'left'}>
              {errors.bio?.message ?? ''}
            </Text>
          </VStack>
          <CustomButton
            label="Update Bio"
            backgroundColor="secondary.500"
            width={FORM_FIELD_WIDTH}
            disabled={submitDisabled}
            isLoading={isPending}
            buttonType={submitDisabled ? 'button' : 'submit'}
          />
        </form>
      </FormProvider>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/profile/bio/')({
  component: ProfileBio,
});
